<template>
    <v-main>
        <v-form ref="form" class="ml-3 mr-3">
            <v-row>
                <v-col cols="12" sm="12" md="4">
                    <v-select
                        color="secondary"
                        v-model="filters.states"
                        :items="states"
                        label="UF"
                    ></v-select>
                </v-col>

                <v-col cols="12" sm="12" md="4">
                    <v-select
                        color="secondary"
                        :items="statusList"
                        item-text="label"
                        item-value="id"
                        v-model="filters.status"
                        label="Status"
                    ></v-select>
                </v-col>

                <v-col cols="12" sm="12" md="4">
                    <v-select
                        color="secondary"
                        :items="managers"
                        label="Gerente"
                        v-model="filters.manager"
                        item-text="name"
                        item-value="id"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" md="6">
                    <v-menu
                        ref="menuDecisao"
                        v-model="menuDecisao"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="decisaoRange"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                class="v-input-default"
                                color="secondary"
                                v-model="decisaoRange"
                                label="Data (Inicio/fim) decisão"
                                prepend-icon="event"
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-if="menuDecisao"
                            v-model="dateDecisao"
                            no-title
                            scrollable
                            range
                            locale="pt-br"
                        >
                            <v-spacer></v-spacer>
                            <v-btn depressed color="secondary" @click="menuDecisao = false">Cancelar</v-btn>
                            <v-btn
                                color="secondary"
                                depressed
                                @click="
                                    $refs.menuDecisao.save(formatDateRange(dateDecisao, 'decisao'))
                                "
                                >Salvar</v-btn
                            >
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <v-menu
                        ref="menuValidade"
                        v-model="menuValidade"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="validadeRange"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                class="v-input-default"
                                color="secondary"
                                v-model="validadeRange"
                                label="Data (Inicio/fim) validade"
                                prepend-icon="event"
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-if="menuValidade"
                            v-model="dateValidade"
                            no-title
                            scrollable
                            range
                            locale="pt-br"
                        >
                            <v-spacer></v-spacer>
                            <v-btn color="secondary" depressed @click="menuValidade = false"
                                >Cancelar</v-btn
                            >
                            <v-btn
                                color="secondary"
                                depressed
                                @click="
                                    $refs.menuValidade.save(
                                        formatDateRange(dateValidade, 'validade')
                                    )
                                "
                                >Salvar</v-btn
                            >
                        </v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="mt-0">
                    <v-btn depressed @click="apply" color="secondary" class="v-btn-padded">
                        <span class="text-none">Aplicar</span>
                    </v-btn>

                    <v-btn
                        outlined
                        @click="onFilterClear"
                        depressed
                        color="secondary"
                        class="ml-4 v-btn-padded"
                    >
                        <span class="text-none">Limpar</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-main>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatDateRangeSetKeys } from '@/helpers/data';
export default {
    props: {
        loadFilters: {
            type: Boolean,
            default: true,
        },
        enableStatusOnly: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        filters: {},
        managers: [],
        menuValidade: false,
        menuDecisao: false,
        dateDecisao: null,
        dateValidade: null,
        decisaoRange: null,
        validadeRange: null,
        statusList: [],
    }),
    watch: {
        $statusList(newValue) {
            this.buildStatusList(newValue, this.enableStatusOnly);
        },
        managersList(newValue) {
            this.managers = newValue;
        },
    },
    computed: {
        ...mapGetters('credito', {
            states: 'statesEnumAsArray',
            managersList: 'managersList',
            $statusList: 'statusList',
        }),
    },
    created() {
        if (this.$store.state.credito.managersList) {
            this.managers = this.$store.state.credito.managersList;
        }
        if (this.$store.state.credito.statusList) {
            this.buildStatusList(this.$store.state.credito.statusList, this.enableStatusOnly);
        }
    },
    methods: {
        apply() {
             this.$emit('onApply', { ...this.filters });
        },
        onFilterClear() {
            if (this.$refs.form && this.$refs.form.resetValidation)
                this.$refs.form.resetValidation();
            this.filters = {};
            this.$emit('onApply', { ...this.filters });
            this.validadeRange = null;
            this.decisaoRange = null;
        },
        formatDateRange(range, field) {
            return formatDateRangeSetKeys(range, field, this);
        },
        buildStatusList(newValue, enableStatusOnly) {
            if (!enableStatusOnly || enableStatusOnly.length === 0) {
                this.statusList = newValue;
                return;
            }
            const newArray = newValue.filter(item => enableStatusOnly.includes(item.id));
            this.statusList = newArray;
        },
    },
};
</script>