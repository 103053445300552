<template>
    <v-main>
        <div class="pt-9 pb-9 pr-9 pl-9">
            <v-container>
                <v-row>
                    <Tabs
                        :showCounter="false"
                        ref="tabEl"
                        @update="updateTab"
                        :tabs="tabs"
                        :allowUserChange="true"
                    />
                </v-row>

                <v-row class="mt-16">
                    <v-main v-show="currentTab === 'consultar'">
                        <v-row justify="space-between">
                            <v-col md="6" sm="12" cols="12" lg="6">
                                <MainFilter
                                    @onInputChange="filterInputChanged"
                                    @onClick="mostrarFiltro"
                                />
                            </v-col>
                            <v-col md="6" sm="12" cols="12" lg="6" align="right">
                                <ExportButton
                                    @onClick="onExport"
                                    :status="downloadLimitsRequestStatus"
                                />
                            </v-col>
                        </v-row>

                        <v-row v-show="filter" class="pb-9">
                            <Filters @onApply="searchLimitsListener" />
                        </v-row>
                        <div class="mt-6">
                            <v-data-table
                                :headers="headers"
                                :items="listLimitsResult.items"
                                hide-default-footer
                                class="elevation-0 pb-4 flat-container"
                                id="table-meus-clients-consulta"
                                @click:row="onClickRow"
                            >
                                <template v-slot:[`item.status`]="{ item }">
                                    <v-chip
                                        small
                                        :class="`status-chip ${getCredito(item.status).color}`"
                                    >
                                        {{ getCredito(item.status).label }}
                                    </v-chip>
                                </template>

                                <template slot="no-data">
                                    <div>Não há dados</div>
                                </template>

                                <template v-slot:footer>
                                    <v-pagination
                                        color="secondary"
                                        v-model="page"
                                        class="mt-3"
                                        :length="listLimitsResult.totalPages"
                                        :total-visible="7"
                                    ></v-pagination>
                                </template>
                            </v-data-table>
                            <div
                                class="text-center mt-3"
                                v-if="limitsRequestStatus === RequestStatusEnum.START"
                            >
                                <v-progress-circular
                                    :size="70"
                                    :width="6"
                                    color="green"
                                    indeterminate
                                ></v-progress-circular>
                            </div>
                        </div>
                    </v-main>
                    <v-main v-show="currentTab === 'solicitar'">
                        <Solicitacoes />
                    </v-main>
                </v-row>
            </v-container>
        </div>
        <Loader mode="overlay" v-if="downloadLimitsRequestStatus === RequestStatusEnum.START" />
    </v-main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Solicitacoes from '@/mfes/credito/views/Solicitacoes';
import ExportButton from '@/components/base/ExportButton';
import { notification } from '@/helpers/notification';
import Filters from '../components/_Filters';
import { headers } from './_headers';
import Tabs from '@/components/base/Tabs';
import MainFilter from '@/components/base/mainfilter/MainFilter';
import fileSaver from '@/helpers/fileSaver';
import { CreditStatusColors, RequestStatusEnum } from '@/helpers/consts';
import Loader from '@/components/base/Loader';

export default {
    name: 'Consulta',
    components: {
        Tabs,
        MainFilter,
        Solicitacoes,
        Filters,
        ExportButton,
        Loader,
    },
    data: () => ({
        loading: false,
        page: 1,
        filter: false,
        dateVencimento: null,
        dateEmissao: null,
        menuVencimento: false,
        menuInicio: false,
        managers: [],
        filters: {},
        currentTab: 'consultar',
        tabs: [
            {
                name: 'Consultar',
                path: 'consultar',
            },
            {
                name: 'Solicitações',
                path: 'solicitar',
            },
        ],
        headers,
        RequestStatusEnum,
    }),
    computed: {
        ...mapGetters('credito', {
            limitsRequestStatus: 'limitsRequestStatus',
            listLimitsResult: 'listLimitsResult',
            selectedProduct: 'selectedProduct',
            downloadLimitsRequestStatus: 'downloadLimitsRequestStatus',
            downloadLimitsResult: 'downloadLimitsResult',
        }),
    },
    methods: {
        ...mapActions('credito', {
            listLimits: 'listLimits',
            resetListLimits: 'resetListLimits',
            downloadLimits: 'downloadLimits',
        }),
        updateTab(data) {
            this.currentTab = data.path;
        },
        mostrarFiltro() {
            this.filter = !this.filter;
        },
        onExport() {
            let filters = {
                ...this.filters,
                produto: this.selectedProduct.id,
            };
            const payload = {
                id_produto: this.selectedProduct.id,
                tipo: 1,
                filters,
            };
            this.downloadLimits(payload);
        },
        searchLimitsListener(data) {
            this.filters = { ...data };
            this.searchLimits();
        },
        getCredito(key) {
            return CreditStatusColors[parseInt(key, 10)];
        },
        filterInputChanged(value) {
            this.filters.nomeOuDoc = value;
            if (value.length >= 3) this.searchLimits();
            if (value === '') this.searchLimits();
        },
        onClickRow(el) {
            this.$router.push({ path: `/admin/credito/limites/${el.id}/cliente` });
        },
        async searchLimits() {
            let filters = {
                ...this.filters,
                page: this.page - 1,
                limit: 10,
                produto: this.selectedProduct.id,
            };
            const payload = {
                id_produto: this.selectedProduct.id,
                tipo: 1,
                filters,
            };
            await this.listLimits(payload);
        },
    },
    watch: {
        page() {
            this.searchLimits();
        },
        limitsRequestStatus(newValue) {
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    'Ocorreu um erro ao requisitar a lista de limites: ' + newValue.message
                );
                return RequestStatusEnum.ERROR;
            }
        },
        downloadLimitsResult(newValue) {
            return fileSaver(newValue.file, newValue.file.type, 'limites.csv');
        },
        downloadLimitsRequestStatus(newValue) {
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    'Ocorreu um erro ao exportar a lista de limites: ' + newValue.message
                );
                return RequestStatusEnum.ERROR;
            }
        },
    },
    async created() {
        this.resetListLimits();
        await this.searchLimits();
    },
    mounted() {
        if (this.$route && this.$route.query.tipo === 'solicitar') {
            this.$refs.tabEl.next();
        }
    },
};
</script>
<style lang="scss">
@import '@/assets/scss/Sticky-table';
@include stickytable('#table-meus-clients-consulta', 1, (0));
#table-meus-clients-consulta {
    tr:hover td {
        opacity: 0.8 !important;
        cursor: pointer;
    }
}
</style>
<style scoped lang="scss">
@import './Consulta.scss';
</style>
