<template>
    <section>
        <v-row justify="space-between">
            <v-col md="6" sm="12" cols="12" lg="6">
                <MainFilter @onInputChange="filterInputChanged" @onClick="mostrarFiltro" />
            </v-col>

            <v-col md="6" sm="12" cols="12" lg="6" align="right">
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="secondary" large depressed dark v-bind="attrs" v-on="on">
                            Solicitar
                            <span><v-icon>mdi-chevron-down</v-icon></span>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                            <v-list-item-title @click="requestByType('individual')"
                                >Individual</v-list-item-title
                            >
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title @click="requestByType('batch')"
                                >Por lote</v-list-item-title
                            >
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>
        <v-row v-show="filter" class="pb-9">
            <Filters
                :enableStatusOnly="enableStatusOnly"
                :loadFilters="false"
                @onApply="searchLimitsListener"
            />
        </v-row>
        <v-row class="mt-6 ml-0 mr-0">
            <v-main>
                <v-data-table
                    :headers="headers"
                    :items="listLimitsPerBatchResult.items"
                    hide-default-footer
                    class="elevation-0 pb-4 flat-container"
                    id="table-meus-clients-consulta-lote"
                    @click:row="onClickRow"
                >
                    <template v-slot:[`item.status`]="{ item }">
                        <v-chip small :class="`status-chip ${getCredito(item.status).color}`">
                            {{ getCredito(item.status).label }}
                        </v-chip>
                    </template>

                    <template slot="no-data">
                        <div>Não há dados</div>
                    </template>

                    <template v-slot:footer>
                        <v-pagination
                            color="secondary"
                            v-model="page"
                            class="mt-3"
                            :disabled="limitsPerBatchRequestStatus === RequestStatusEnum.START"
                            :length="listLimitsPerBatchResult.totalPages"
                            :total-visible="7"
                        ></v-pagination>
                    </template>
                </v-data-table>

                <Loader
                    align="center"
                    justify="center"
                    v-if="limitsPerBatchRequestStatus === RequestStatusEnum.START"
                />
            </v-main>
        </v-row>
        
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import MainFilter from '@/components/base/mainfilter/MainFilter';
import Filters from '../components/_Filters';
import { headers } from './_headers';
import Loader from '@/components/base/Loader';
import { RequestStatusEnum, CreditStatusColors } from '@/helpers/consts';
import { unFormatDate } from '@/helpers/data';

export default {
    components: {
        MainFilter,
        Filters,
        Loader,
    },
    data: () => ({
        filter: false,
        selectedFile: null,
        filters: {},
        page: 1,
        headers,
        enableStatusOnly: [1, 4],
        RequestStatusEnum,
    }),
    watch: {
        page() {
            this.searchLimits();
        },
       
    },
    async mounted() {
        this.searchLimits();
    },
    methods: {
        ...mapActions('credito', {
            listLimitsPerBatch: 'listLimitsPerBatch',
        }),
        getCredito(key) {
            return CreditStatusColors[parseInt(key, 10)];
        },
        filterInputChanged(value) {
            this.filters.nomeOuDoc = value;
            if (value.length >= 3) this.searchLimits();
            if (value === '') this.searchLimits();
        },
        mostrarFiltro() {
            this.filter = !this.filter;
        },
        requestByType(type) {
            if (type === 'batch') {
                this.$router.push({ path: '/admin/credito/limites/solicitar_lote' });
                return;
            }
            this.$router.push({ path: '/admin/credito/limites/solicitar' });
        },
        searchLimitsListener(data) {
            this.filters = { ...data };
            this.searchLimits();
        },
        async searchLimits() {
            let filters = {
                ...this.filters,
                page: this.page - 1,
                limit: 10,
                produto: this.selectedProduct.id,
            };
            if (!filters.status && this.enableStatusOnly) {
                filters.status = this.enableStatusOnly;
            }
            if (this.filters.vencimento) {
                filters = { ...filters, vencimento: unFormatDate(this.filters.vencimento) };
            }
            if (this.filters.decisao) {
                filters = { ...filters, decisao: unFormatDate(this.filters.decisao) };
            }
            const payload = {
                id_produto: this.selectedProduct.id,
                tipo: 1,
                filters,
            };
            await this.listLimitsPerBatch(payload);
        },
        onClickRow(el) {
            this.$router.push({ path: `/admin/credito/limites/${el.id}/cliente` });
        },
    },
    computed: {
        ...mapGetters('credito', {
            listLimitsPerBatchResult: 'listLimitsPerBatchResult',
            limitsPerBatchRequestStatus: 'limitsPerBatchRequestStatus',
            selectedProduct: 'selectedProduct',
        }),
    },
};
</script>

<style lang="scss">
@import '@/assets/scss/Sticky-table';
@include stickytable('#table-meus-clients-consulta-lote', 1, (0));
#table-meus-clients-consulta-lote {
    tr:hover td {
        opacity: 0.8;
        cursor: pointer;
    }
}
</style>
